<template>
  <main>
      <NavView />
  </main>
</template>

<script>
import NavView from './views/NavView.vue';
export default {
  components: {
    NavView
  }
}
</script>

<style lang="scss">
* {
  font-family: 'Poppins', sans-serif;
}
main > nav
{
  display: none;
}

</style>
