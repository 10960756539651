<template>
  <section>
    <header>
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <h1>Ton geste peut sauver une vie</h1>
          <p>Décès du à l'insuffisance rénale chronique au Bénin : <strong> Plus jamais ça.</strong> <br>
            Ensemble pour sauver des vies.<br>
            Collecte de fonds pour l'assistance des personnes souffrant de l'IRC. <br>
            Ton geste peut sauver une vie. </p>
        </div>
      </div>
    </header>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FNouvelleAfriqueMag%2Fvideos%2F293249453319961%2F&show_text=false&width=560&t=0" width="100%" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
      </div> 
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <form action="">
            <p>Veuillez remplir ce formulaire pour soumettre votre don.</p>
            <p>Total collecté à ce jour : <span class="amount">{{ totalAmount.total }} FCFA</span> </p>
            <hr>
            <div class="mb-4 mt-4">
              <label for="name">Votre nom</label>
              <input type="text" class="form-control" name="name" v-model="name" placeholder="Jean DOSSOU">
            </div>

            <div class="mb-4">
              <label for="name">Votre numéro de téléphone</label>
              <input type="text" class="form-control" name="phone" v-model="phone" placeholder="+22997334389">
            </div>

            <div class="row">
              <div class="col-lg-8">
                <div class="mb-4">
                  <label for="name">Combien voulez-vous donner (FCFA) ? <span class="text-danger">*</span></label>
                  <input type="number" class="form-control" name="amount" placeholder="5000" v-model="amount" required>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <button @click.prevent="sendMonney" type="submit" class="btn btn-perso"> 
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                Envoyer ma contribution
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script >
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";


export default {
  name: 'HomeView',
  data() {
    return {
      name: '',
      phone: '',
      amount: 0,
      dataStatus: false,
      loading: false,
      totalAmount: {
        total: 0
      }
    }
  },
  methods: {
    intialInput(){
      this.name = '',
      this.phone = '',
      this.amount = 0
    },
    sendMonney() {
      this.loading = true
      if (this.amount >= 500) {
        openKkiapayWidget({
          amount: this.amount,
          phone: this.phone,
          api_key: "43db303033ca11eaa71dbd8ede52397f",
        })
      } else {
        this.loading = false
        this.$swal({
          icon: 'error',
          title: 'Ooops...',
          text: 'Veuillez saisir un montant minimum de 500'
        });
      }
    },
    successHandler(response) {
      this.loading = false
      this.saveData(this.name, this.phone, this.amount, response.transactionId);
      this.getTotalAmount();
      this.intialInput();

      this.$swal({
        icon: 'success',
        title: 'Merci',
        text: 'Votre contribution est prise en compte avec succès. Merci...'
      });
      
    },
    getTotalAmount(){
      this.axios
        .get('https://megatech-web.com/serges/getAmount.php')
        .then(response => {this.totalAmount = response.data.content})
        .catch(error => {console.log(error)})
    },
    saveData(name, phone, amount, tId){
      this.axios
        .get('https://megatech-web.com/serges/saveAmount.php?name='+ name +'&phone='+ phone +'&amount='+ amount +'&transactionId='+ tId)
        .then (response => {
          this.dataStatus = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.loading = false)
    }
  },
  mounted() {
    addKkiapayListener('success', this.successHandler)
    this.getTotalAmount();
  },
  beforeUnmount() {
    removeKkiapayListener('success', this.successHandler)
  },
  unmounted(){
  }
}
</script>


<style lang="scss">
header {
  background: #ddf4ee;
  position: relative;
  z-index: 1;
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding-top: 50px;
  min-height: 300px;
  text-align: center;
}

header .row {
  margin: 0;
}

header:after {
  background: url(https://cdn.marketing123.123formbuilder.com/wp-content/themes/marketing123/assets/css/../images/seo/white-brush-mask.svg) no-repeat bottom;
  content: "";
  height: 80px;
  width: 100%;
  position: absolute;
  background-size: cover;
  z-index: 2;
  bottom: 0;
  left: 0;
}

nav {
  margin-bottom: 15px;
}

nav a {
  color: #01a063;
  text-decoration: none;
  font-size: 20px;
}

form {
  padding: 25px;
  margin-top: 50px;
  background-color: #f2faf8;
  border-radius: 10px;
}

input[type='text'],
input[type='number'] {
    padding-top: 11px;
    padding-bottom: 11px;
}
.btn-perso {
    background: #01a063;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-bottom: 10px;
    display: block;
    width: 60%;
    color: #FFF;
}
.btn-perso:hover {
    background: #016f45;
    color: #FFF;
}
.amount {
  background: #01a063;
  padding: 5px;
  color: #FFF;
  font-weight: bold;
  border-radius: 5px;
}
</style>
