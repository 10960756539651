import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

//import { VueRecaptchaPlugin } from 'vue-recaptcha'

axios.defaults.baseURL = 'https://megatech-web.com/'

createApp(App)
.use(store)
.use(router)
.use(VueSweetalert2)
.use(VueAxios, axios)
/*.use(VueRecaptchaPlugin, {
    v2SiteKey: 'YOUR_V2_SITEKEY_HERE',
    v3SiteKey: 'YOUR_V3_SITEKEY_HERE',
})*/
.mount('#app')
