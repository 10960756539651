<template>
     <nav>
      <router-link to="/">Accueil</router-link> |
      <router-link to="/about">À propos</router-link>
    </nav>
    <router-view/>
</template>

<script>
    export default {
        name: 'NavView'
    }
</script>